import { Injectable } from '@angular/core';
import { UserAccountStore } from './user-account.store';
import {
  OrganizationSettings,
  UserAccount,
  UserSession,
  UserSettings
} from 'sustainment-component';
import { IdentityApi } from 'sustainment-component';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class UserAccountAction {
  public constructor(
    private _store: UserAccountStore,
    private _identityApi: IdentityApi
  ) {}

  public isAuthenticated(): boolean {
    const user = this._store.getValue().userSession;
    const isAuthenticated = !!(
      user?.appKey &&
      new Date(user.appKeyExpirationUtc) > new Date() &&
      !user.isLockedOut
    );
    return isAuthenticated;
  }

  public setUserSession(userSession: UserSession): void {
    this._store.update({
      sustainmentId: userSession.sustainmentId,
      userId: userSession.userAccountId,
      userSession
    });
  }

  public showedWelcome(): void {
    this._store.update((state) => {
      return {
        ...state,
        userSession: { ...state.userSession!, showedWelcome: true }
      };
    });
    this._identityApi.showedWelcome().subscribe();
  }

  public setUserAccount(userAccount: UserAccount): void {
    this._store.update({ userAccount });
  }

  public removeUserSession(): void {
    this._store.update({ userSession: null });
  }

  public updateOrganizationName(name: string): void {
    this._store.update((value) => {
      const userAccount = value.userAccount;
      userAccount.organizations[0].name = name;
      return { userAccount: userAccount };
    });
  }

  public updateSettings(
    session: UserSession,
    settings: UserSettings
  ): Observable<null> {
    return this._identityApi
      .updateSettings(session.sustainmentId, settings)
      .pipe(
        tap(() => {
          session.settings = settings;
          this._store.update({ userSession: session });
        })
      );
  }

  public updateUserProblems(problems: { id: number; text: string }[]): void {
    this._store.update((value) => ({
      ...value,
      userSession: value.userSession
        ? { ...value.userSession, problems: problems.map((x) => x.id) }
        : undefined
    }));
    this._identityApi
      .updateUserProblems(this._store.getValue().sustainmentId, problems)
      .subscribe();
  }

  public updateOrganizationSettings(
    session: UserSession,
    settings: OrganizationSettings
  ): Observable<null> {
    return this._identityApi
      .updateOrganizationSettings(session.sustainmentId, settings)
      .pipe(
        tap(() => {
          session.orgSettings = settings;
          this._store.update({ userSession: session });
        })
      );
  }

  public updateUserRoles(userRoles: string[]): void {
    this._store.update((state) => {
      return {
        ...state,
        userSession: { ...state.userSession, userRoles } as UserSession
      };
    });
  }
}
